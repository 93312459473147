<template>
  <div class="merge-bill pb-3" style="height: auto">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">合併帳單</p>
    </div>
    <div class="tw-container">
      <!-- 訂單列表 -->
      <div class="table-responsive mb-3" style="height: 400px">
        <table class="table table-hover">
          <thead class="float-header">
            <tr>
              <th>封面</th>
              <th class="text-start" style="min-width: 150px">商品</th>
              <th class="text-start" style="min-width: 150px">款式</th>
              <th style="min-width: 80px">到貨狀況</th>
              <th style="min-width: 80px">明細</th>
            </tr>
          </thead>
          <tbody>
            <!-- parent -->
            <tr>
              <td class="fw-bolder bg-info bg-gradient" colspan="5">
                預合併帳單單號: {{ parentHeader.id }}
              </td>
            </tr>
            <tr v-for="data in parentHeader.billBodies" :key="data.id">
              <td>
                <Image
                  :imageHash="data.merchandiseImgHash"
                  size="s"
                  v-if="data.merchandiseImgHash"
                  :alt="data.merchandiseImgHash"
                  style="width: 50px; height: 50px"
                ></Image>
                <img
                  src="@/assets/other-images/noImg.png"
                  v-else
                  style="width: 50px; height: 50px"
                />
              </td>
              <td class="text-start">
                {{ data.merchandiseName }}
              </td>
              <td class="text-start">
                {{ data.style }}
              </td>
              <td>
                <p class="fw-bolder" :class="data.textColorForArrialStatus">
                  到貨狀況: {{ data.arrivalStatus }}
                </p>
              </td>
              <td>{{ data.quantity }} X {{ data.price }}</td>
            </tr>
            <tr>
              <td class="text-end p-3 fw-bolder" colspan="5">
                <p>帳單平衡: {{ parentHeader.balanceMoney ? parentHeader.balanceMoney : 0 }}</p>
                <div :title="parentHeader.discountName"  v-if="parentHeader.discountAmount">
                  結單優惠: 
                  <span v-if="parentHeader.accumulateDiscount">-{{ parentHeader.discountAmount }}</span>
                  <del class="text-secondary" v-else>免運不處理</del>
                </div>
              </td>
            </tr>
            <!-- child -->
            <template v-for="data in childHeaders" :key="data.id">
              <tr>
                <td class="fw-bolder bg-warning bg-gradient" colspan="5">
                  被合併帳單單號: {{ data.id }}
                </td>
              </tr>
              <tr v-for="body in data.billBodies" :key="body.id">
                <td>
                  <Image
                    :imageHash="body.merchandiseImgHash"
                    size="s"
                    v-if="body.merchandiseImgHash"
                    :alt="body.merchandiseImgHash"
                    style="width: 50px; height: 50px"
                  ></Image>
                  <img
                    src="@/assets/other-images/noImg.png"
                    v-else
                    style="width: 50px; height: 50px"
                  />
                </td>
                <td class="text-start">
                  {{ body.merchandiseName }}
                </td>
                <td class="text-start">
                  {{ body.style }}
                </td>
                <td>
                  <p class="fw-bolder" :class="body.textColorForArrialStatus">
                    到貨狀況: {{ body.arrivalStatus }}
                  </p>
                </td>
                <td>{{ body.quantity }} X {{ body.price }}</td>
              </tr>
              <tr>
                <td class="text-end p-3 fw-bolder" colspan="5">
                  <p>帳單平衡: {{ data.balanceMoney ? data.balanceMoney : 0 }}</p>
                  <div :title="data.discountName"  v-if="data.discountAmount">
                    結單優惠: 
                    <span v-if="data.accumulateDiscount">-{{ data.discountAmount }}</span>
                    <del class="text-secondary" v-else>免運不處理</del>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- 顧客備註 -->
      <div class="mb-3 border rounded-3 p-3">
        <p class="tw-border-start fw-bolder mb-3">顧客備註(僅現賣家看到)</p>
        <textarea
          class="form-control"
          cols="10"
          rows="2"
          disabled
          v-model="buyerNote"
        ></textarea>
      </div>
      <!-- 結單資訊 -->
      <CheckoutInfo
        :participantId="participantId"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromCheckoutInfoData="fromCheckoutInfoData"
      ></CheckoutInfo>
      <!-- 發票資訊 -->
      <ReceiptInfo
        v-if="!p_loading && customerCheckoutCanSetReceiptInfo"
        :propsToReceiptInfo="propsToReceiptInfo"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromReceiptInfoData="fromReceiptInfoData"
      ></ReceiptInfo>
      <!-- 金額 -->
      <div class="row">
        <div class="col-md-6 p-0"></div>
        <div class="col-md-6 p-0">
          <div class="mb-3">
            <span class="tw-border-start fw-bolder me-3">金額</span>
          </div>
          <div>
            <ul class="fw-bolder">
              <li class="mb-2">
                運費:
                {{
                  propsToCheckoutInfo.shippingCost
                    ? propsToCheckoutInfo.shippingCost
                    : 0
                }}元
              </li>
              <li class="mb-2">帳單平衡: {{ balanceTotal }}元</li>
              <li class="mb-2">訂單金額: {{ bodyTotal }}元</li>
              <li class="mb-2">
                結單優惠: 
                <span class="text-success" v-if="discountAmount">
                  <span v-if="discountAmount">
                    {{ discountName }}(折扣:{{ discountAmount }}元)
                  </span>
                  <del class="text-secondary" v-else >
                    {{ discountName }}(折扣:{{ discountAmount }}元)
                  </del>
                </span>
                <span class="text-danger" v-else>無套用結單優惠</span>
              </li>
              <li>
                購物金折抵:
                {{
                  propsToCheckoutInfo.money ? propsToCheckoutInfo.money : 0
                }}元
              </li>
            </ul>
            <hr />
            <div class="d-flex justify-content-between">
              <p class="tw-text-size18 text-danger fw-bolder">
                應付金額: {{ $methods.numberToFixed(shoulBePay) }}元
              </p>
              <div class="form-check p-0" v-if="!p_loading && showDetail">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  value=""
                  id="ShowDetail"
                  v-model="d4showDetail"
                />
                <label class="form-check-label fw-bolder" for="ShowDetail">
                  代客直寄
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-end">
      <button class="tw-btn tw-btn-secondary me-3" @click="goBack">返回</button>
      <button class="tw-btn tw-btn-success me-3" @click="mergeHeader">
        完成
      </button>
    </div>
  </div>
</template>

<script>
// components
import CheckoutInfo from '../../../components/checkoutOrder/CheckoutInfo.vue'
import ReceiptInfo from '../../../components/checkoutOrder/ReceiptInfo.vue'

// service
import { checkStoreDiscountTypeIsDiscountShippingCost } from '../../../methods/service/storeDiscountService'

export default {
  components: {CheckoutInfo, ReceiptInfo},
  data() {
    return {
      serverToken: '',
      storeId: 0,
      participantId: 0,
      parentHeaderId: 0,
      childHeaderIds: [],
      parentHeader: {},
      childHeaders: [],
      // 傳給子層(出貨付款資訊)
      propsToCheckoutInfo: {
        selectShppingType: '',
        selectPaymentType: '',
        name: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: 0,
      },
      // 傳給子層(發票資訊)
      propsToReceiptInfo: {
        title: '',
        number: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
      },
      discountType: "MERGE_BILL_ACCUMULATE_DISCOUNT",
      discountName: "併單後累加優惠",
      discountAmount: 0,
      balanceTotal: 0,
      bodyTotal: 0,
      shoulBePay: 0,
      // 代客直寄
      d4showDetail: false,
      // 顧客備註
      buyerNote: ''
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  computed: {
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 顧客可自行調整結單數量
    customerCanEditCheckoutQuantity() {
      return this.$store.state.Prefer.p_store.customerCanEditCheckoutQuantity[0].selected
    },
    // 偏好 > 結單可填寫發票資訊
    customerCheckoutCanSetReceiptInfo() {
      return this.$store.state.Prefer.p_store.customerCheckoutCanSetReceiptInfo[0].selected
    },
    // 偏好 > 代客直寄
    showDetail() {
      return this.$store.state.Prefer.p_store.showDetail[0].selected
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.participantId = this.$route.query.participantId
      this.parentHeaderId = this.$route.query.parent
      this.childHeaderIds = this.$route.query.child.split(',')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getShippingTypes}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.getBillInfo(storeInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得帳單資訊
    getBillInfo(storeInfo) {
      const vm = this
      const getBillInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 27,
        ids: [this.parentHeaderId, ...this.childHeaderIds],
        methods: '{getBuyer,getBalanceMoney,getTotalPrice,getBodyPrice,getBillBodies{getMerchOrder{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles}},getRecipientPostalCodeDetail,getReceiptPostalCodeDetail}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billInfo = res.data[0].objects
            vm.sortOutBillInfo(billInfo, storeInfo)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    /**
     * 整理帳單資訊
     * @param {*} billInfo 所有帳單資訊
     * @param {*} storeInfo 賣場資訊
     */
    sortOutBillInfo(billInfo, storeInfo) {
      console.log(billInfo)
      // 整理訂單列表
      this.bodyTotal = 0
      this.balanceTotal = 0
      this.discountAmount = 0
      billInfo.forEach(item => {
        this.bodyTotal += item.bodyPrice
        this.balanceTotal += item.balanceMoney ? item.balanceMoney : 0
        
        // 結單優惠的累加不處理免運的優惠
        item.accumulateDiscount = !checkStoreDiscountTypeIsDiscountShippingCost(item.discountType); // 在帳單單頭上紀錄是否需要累加結單優惠
        if(item.accumulateDiscount) this.discountAmount += item.discountAmount ? item.discountAmount : 0

        item.billBodies.forEach(body => {
          // 取商品圖
          body.merchandiseImgHash = null
          body.merchOrder.merchandise.merchandisePictureLinks.some(img => {
            if (img.front) return body.merchandiseImgHash = img.imageHash
          })
          // 取商品名
          body.merchandiseName = body.merchOrder.merchandise.name
          // 取款式
          body.style = ''
          body.merchOrder.merchandiseStyles.forEach(style => {
            body.style += `${style.name} `
          })
          // 到貨狀況
          body.arrivalStatus = null
          body.textColorForArrialStatus = 'text-danger'
          if (body.merchOrder.allocatedQuantity === 0) {
            body.arrivalStatus = '未到貨'
            body.textColorForArrialStatus = 'text-secondary'
          } else if (body.merchOrder.quantity === body.merchOrder.allocatedQuantity) {
            body.arrivalStatus = '全到貨'
            body.textColorForArrialStatus = 'text-success'
          } else if (body.quantity === body.merchOrder.quantity && body.merchOrder.allocatedQuantity > 0) {
            body.arrivalStatus = `到貨: ${body.merchOrder.allocatedQuantity}個`
            body.textColorForArrialStatus = 'text-warning'
          } else {
            body.arrivalStatus = '分批結單，無法判斷到貨數量'
            body.textColorForArrialStatus = 'text-danger'
          }
        })
        // 將 parent 與 child 分開紀錄
        if(this.parentHeaderId == item.id) {
          this.parentHeader = item
          this.d4showDetail = item.showDetail
          this.buyerNote = item.buyer.note
          this.balanceTotal = item.balanceMoney
          // 傳給子層(出貨付款資訊)
          let selectShppingType = ''
          if (item.shippingType) {
            storeInfo.shippingTypes.some(shippingType => {
              if (shippingType.name === item.shippingType) return selectShppingType = `${shippingType.name},${shippingType.cost}`
            })
          }
          this.propsToCheckoutInfo = {
            selectShppingType: selectShppingType,
            selectPaymentType: item.paymentType ? item.paymentType : '',
            name: item.recipient ? item.recipient : '',
            phone: item.recipientPhoneNumber ? item.recipientPhoneNumber : '',
            selectCity: item.recipientPostalCode ? item.recipientPostalCodeDetail.City : '',
            selectArea: item.recipientPostalCode ? item.recipientPostalCode : '',
            address: item.receivingAddress ? item.receivingAddress : '',
            shippingCost: item.shippingCost ? item.shippingCost : 0,
            D4shippingCost: item.shippingCost ? item.shippingCost : 0,
            note: item.note ? item.note : '',
            money: item.walletUseAmount ? item.walletUseAmount : 0,
            wallet: item.buyer.wallet,
          }
          // 傳給子層(發票資訊)
          this.propsToReceiptInfo = {
            title: item.receiptTitle ? item.receiptTitle : '',
            number: item.receiptBusinessAdminNumber ? item.receiptBusinessAdminNumber : '',
            phone: item.receiptPhoneNumber ? item.receiptPhoneNumber : '',
            selectCity: item.receiptPostalCode ? item.receiptPostalCodeDetail.City : '',
            selectArea: item.receiptPostalCode ? item.receiptPostalCode : '',
            address: item.receiptAddress ? item.receiptAddress : '',
          }
        } else {
          this.childHeaders.push(item)
        }
      })

      this.bodyTotal = this.$methods.numberToFixed(this.bodyTotal)
      this.balanceTotal = this.$methods.numberToFixed(this.balanceTotal)
      this.discountAmount = this.$methods.numberToFixed(this.discountAmount)
      this.shoulBePay = this.countTotalPrice();
    },
    // 來自出貨付款的訊息 (子層 > 父層)
    fromCheckoutInfoData(object) {
      this.propsToCheckoutInfo = object
      this.countTotalPrice();
    },
    // 計算總額
    countTotalPrice() {
      const shippingCost = this.propsToCheckoutInfo.shippingCost;
      const balanceTotal = this.balanceTotal;
      const bodyTotal = this.bodyTotal;
      const money = this.propsToCheckoutInfo.money;
      const discountAmount = this.discountAmount;

      this.shoulBePay = this.$methods.numberToFixed(shippingCost + balanceTotal + bodyTotal - money - discountAmount);
    },
    // 來自發票資訊的訊息 (子層 > 父層)
    fromReceiptInfoData(object) {
      this.propsToReceiptInfo = object
    },
    // 合併帳單
    mergeHeader() {
      this.$methods.switchLoading('show')
      const vm = this
      const mergeHeaderApi = `${process.env.VUE_APP_API}/bill/mergeHeaders`
      const header = {
        authorization: this.serverToken
      }
      const data = {
        parentHeader: {
          storeId: this.parentHeader.storeId,
          buyerId: this.parentHeader.buyerId,
          note: this.propsToCheckoutInfo.note,
          shippingType: this.propsToCheckoutInfo.selectShppingType ? this.propsToCheckoutInfo.selectShppingType.split(',')[0] : null,
          shippingCost: this.propsToCheckoutInfo.shippingCost ? this.propsToCheckoutInfo.shippingCost : 0,
          recipient: this.propsToCheckoutInfo.name,
          recipientPhoneNumber: this.propsToCheckoutInfo.phone,
          recipientPostalCode: this.propsToCheckoutInfo.selectArea ? this.propsToCheckoutInfo.selectArea : null,
          receivingAddress: this.propsToCheckoutInfo.address,
          paymentType: this.propsToCheckoutInfo.selectPaymentType,
          walletUseAmount: this.propsToCheckoutInfo.money ? this.propsToCheckoutInfo.money : 0,
          receiptTitle: this.propsToReceiptInfo.title ? this.propsToReceiptInfo.title : null,
          receiptBusinessAdminNumber: this.propsToReceiptInfo.number ? this.propsToReceiptInfo.number : null,
          receiptPhoneNumber: this.propsToReceiptInfo.phone ? this.propsToReceiptInfo.phone : null,
          receiptPostalCode: this.propsToReceiptInfo.selectArea ? this.propsToReceiptInfo.selectArea : null,
          receiptAddress: this.propsToReceiptInfo.address ? this.propsToReceiptInfo.address : null,
          showDetail: this.d4showDetail,
          discountName: this.discountAmount ? this.discountName : null,
          discountType: this.discountAmount ? this.discountType : null,
          discountAmount: this.discountAmount ? this.discountAmount : null,
        },
        childHeaderIds: [...this.childHeaderIds, this.parentHeaderId]
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: mergeHeaderApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            vm.goBack()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 返回
    goBack() {
      if (window.history.length === 1) {
        this.$router.push(`/seller/store/${this.storeId}/bill`);
      } else {
        this.$router.back();
      }
    },
  },
}
</script>